var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading === false)?_c('div',{staticClass:"row"},[_c('div',{class:{
      [_vm.$style.card__register]: true,
    }},[_c('div',{class:{
        [_vm.$style.card__register__image]: true,
      }},[_c('img',{attrs:{"src":"https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482","width":"170","height":"82.13"}})]),_c('div',{staticClass:"pbz-font subtitle-md-medium mt-5 text-left",staticStyle:{"color":"#1a1a1a"}},[_vm._v(" Konfirmasi Undangan ")]),_c('div',{staticClass:"pbz-font my-5 text-center"},[_c('div',{staticClass:"d-flex justify-content-around align-items-center",staticStyle:{"width":"100%","height":"48px","background":"#FFFFFF","border":"1px solid #CCCCCC","box-sizing":"border-box","border-radius":"80px","cursor":"pointer","font-size":"13px"}},[_c('div',{staticClass:"pbz-font button-xs-medium",staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","align-items":"center","padding":"10px 20px","width":"45%","height":"38px","color":"#999999"},style:([
            _vm.hasAccount && {
              background: '#E00000',
              boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
              color: '#FFFFFF !important',
              borderRadius: '60px',
            }
          ]),on:{"click":function($event){return _vm.handleHasAccount(true)}}},[_vm._v(" Sudah Punya Akun ")]),_c('div',{staticClass:"pbz-font button-xs-medium",staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","align-items":"center","width":"45%","height":"38px","color":"#999999"},style:([
            !_vm.hasAccount && {
              background: '#E00000',
              boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.15)',
              color: '#FFFFFF !important',
              borderRadius: '60px',
            }
          ]),on:{"click":function($event){return _vm.handleHasAccount(false)}}},[_vm._v(" Belum Punya Akun ")])])]),(!_vm.hasAccount)?_c('div',{staticClass:"mb-5"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.emailInvited)?_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
          },attrs:{"prop":"email","rules":[{ required: true }]}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
              [_vm.$style.container__form__input__disabled]: _vm.emailInvited && true,
            },attrs:{"disabled":"disabled"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',{style:([
                _vm.emailInvited && {
                  background: 'linear-gradient(#ffffff 0%, #e4e9f0 100%) no-repeat !important',
                }
              ])},[_vm._v(" Email ")])])],1):_vm._e(),(_vm.phoneInvited)?_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
          },attrs:{"prop":"email","rules":[{ required: true }]}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
              [_vm.$style.container__form__input__disabled]: _vm.phoneInvited && true,
            },attrs:{"disabled":"disabled"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',{style:([
                _vm.phoneInvited && {
                  background: 'linear-gradient(#ffffff 0%, #e4e9f0 100%) no-repeat !important',
                }
              ])},[_vm._v(" No. Handphone ")])])],1):_vm._e(),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
          },attrs:{"prop":"username","rules":[{ required: true }]}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(" Nama Pengguna ")])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
          },attrs:{"prop":"name","rules":[{ required: true }]}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(" Nama Lengkap ")])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('password'),
          },attrs:{"prop":"password","rules":[{
            required: true,
            validator: _vm.passwordValidator,
          }]}},[_c('a-input-password',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.password'))+" ")])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('confirm_password'),
          },attrs:{"prop":"confirm_password","rules":[{
            required: true,
            validator: _vm.confirmPasswordValidator,
          }]}},[_c('a-input-password',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.confirmPassword'))+" ")])])],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
            [_vm.$style.button__submit]: true,
          },attrs:{"size":"large","html-type":"submit"}},[(!_vm.loading)?_c('div',[_vm._v(" Registrasi ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()])],1)],1):_vm._e(),(_vm.hasAccount)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mt-4"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-model-item',{class:{
              [_vm.$style.container__form]: true,
            },attrs:{"prop":"user_login","rules":[{
              required: true,
              message: _vm.$t('auth.invalidEmailPhoneNumber'),
            }]}},[_c('a-input',{attrs:{"size":"large","placeholder":_vm.$t('auth.emailPhoneLabel')},model:{value:(_vm.form.user_login),callback:function ($$v) {_vm.$set(_vm.form, "user_login", $$v)},expression:"form.user_login"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
                [_vm.$style.label__form]: true,
              }},[_vm._v(" Username ")])],1),_c('a-form-model-item',{class:{
              [_vm.$style.container__form]: true,
            },attrs:{"prop":"password","rules":[{
              required: true,
              message: _vm.$t('auth.invalidPassword'),
            }]}},[_c('a-input-password',{attrs:{"size":"large","placeholder":_vm.$t('auth.password')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
                [_vm.$style.label__form]: true,
              }},[_vm._v(" Kata Sandi ")])],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
              [_vm.$style.button__submit]: true,
            },attrs:{"size":"large","loading":_vm.loading_button,"html-type":"submit"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('auth.login'))+" ")])])],1)],1)]):_vm._e(),_c('div',{staticClass:"mt-3 text-center"},[_c('div',{staticClass:"anotherSign pbz-font body-sm-medium",staticStyle:{"color":"#666666 !important"}},[_vm._v(" "+_vm._s(_vm.$t('auth.anotherRegister'))+" ")])]),_c('div',{staticClass:"mt-5 mb-2",class:{
        [_vm.$style.anotherRegisterCard]: true,
      }},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/PowerBiz/google-logo.png")}}),_c('div',{staticClass:"mx-3 pbz-font body-md-medium"},[_vm._v(" "+_vm._s(_vm.$t('auth.signUpWithGoogle'))+" ")])]),_c('div',{staticClass:"mt-3 mb-2",class:{
        [_vm.$style.anotherRegisterCard]: true,
      }},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/PowerBiz/facebook-logo.png")}}),_c('div',{staticClass:"mx-3 pbz-font body-md-medium"},[_vm._v(" "+_vm._s(_vm.$t('auth.signUpWithFacebook'))+" ")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }