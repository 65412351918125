<template>
  <ConfirmationInviteUser class="mt-3" />
</template>

<script>
import ConfirmationInviteUser from '@/components/cleanui/system/Auth/ConfirmInviteUser'

export default {
  components: {
    ConfirmationInviteUser,
  },
}
</script>

<style>

</style>
